import { z } from 'zod';

import { createNipValidator, createPhoneValidator, createZipCodeValidator } from '@utils/api/validation/common';

export const checkoutItemSchema = z.object({
  id: z.string(),
  quantity: z.number(),
});

export const checkoutSubscriptionItemSchema = checkoutItemSchema.extend({
  emails: z.array(z.string()),
});

export interface CustomerDataSchemaValidatorErrors {
  phone: string;
  zip: string;
}
export const createCustomerDataSchema = (errors: CustomerDataSchemaValidatorErrors) => {
  return z.object({
    name: z.string().min(3).max(50),
    email: z.string().email(),
    phone: createPhoneValidator(errors.phone),
    address: z.string().min(5).max(150),
    zip: createZipCodeValidator(errors.zip),
    city: z.string().min(1).max(100),
    country: z.enum(['PL']),
  });
};

export interface CompanyDataSchemaValidatorErrors {
  nip: string;
  zip: string;
}
export const createCompanyDataSchema = (errors: CompanyDataSchemaValidatorErrors) => {
  return z.object({
    name: z.string().min(1).max(200),
    nip: createNipValidator(errors.nip),
    address: z.string().min(5).max(150),
    zip: createZipCodeValidator(errors.zip),
    city: z.string().min(1).max(100),
    country: z.enum(['PL']),
  });
};

export const promoCodeSchema = z.object({
  id: z.number(),
  title: z.string(),
  code: z.string(),
  promo_type: z.enum(['free-trial', 'yearly-discount', 'deals']),
  percentage_discount: z.number(),
  free_months: z.number(),
  free_users_count: z.number(),
  max_uses: z.number(),
  uses_left: z.preprocess((value) => Number(value), z.number()),
  expires_at: z.string(),
  base_unit: z.number(),
  gains_amount: z.number(),
  applies_to_yearly: z.boolean(),
  applies_to_monthly: z.boolean(),
});

export interface CheckoutSchemaValidatorErrors {
  customerData: CustomerDataSchemaValidatorErrors;
  companyData: CompanyDataSchemaValidatorErrors;
}

export const createCheckoutSchema = (errors: CheckoutSchemaValidatorErrors) => {
  return z.object({
    status: z.enum(['NEW', 'PENDING', 'FINISHED']),
    promoCode: promoCodeSchema.nullable(),
    items: z.array(z.union([checkoutSubscriptionItemSchema, checkoutItemSchema])),
    customerData: z.union([createCustomerDataSchema(errors.customerData), z.null()]),
    companyData: z.union([createCompanyDataSchema(errors.companyData), z.null()]),
  });
};
