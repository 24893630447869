import { getItemById } from './items';
import { checkoutSubscriptionItemSchema } from './schema';
import { CheckoutSchema, CompanyData, CustomerData, PromoCode } from './types';

import { Packages } from '@lib/serverPackages';
import { WRPayment, WRPromoCode } from '@utils/api/server';

export const checkoutToPaymentCreate = (checkout: CheckoutSchema, packages: Packages) => {
  if (!checkout.customerData) throw new Error('Missing customer data.');
  if (!checkout.companyData) throw new Error('Missing company data.');

  if (checkout.items.length !== 1) {
    throw new Error('payment/create only supports one item.');
  }

  const { name, email, phone, address, zip, city, country } = checkout.customerData;

  const {
    name: companyName,
    nip: companyNip,
    address: companyAddress,
    zip: companyZip,
    city: companyCity,
    country: companyCountry,
  } = checkout.companyData;

  const subscriptionCheckoutItem = checkoutSubscriptionItemSchema.safeParse(checkout.items[0]);

  if (!subscriptionCheckoutItem.success) {
    throw new Error('payment/create only supports subscription items.');
  }

  const { id: itemId, emails: userEmails, quantity: usersCount } = subscriptionCheckoutItem.data;

  const item = getItemById(itemId, packages);

  if (!item) {
    throw new Error('Item not found.');
  }

  if (!item.subscriptionOptions) {
    throw new Error('Item is not a subscription.');
  }

  const data: Record<string, any> = {
    packageName: item.subscriptionOptions.packageName,
    client: name,
    email,
    phone,
    address,
    city,
    zip,
    country,
    companyName,
    companyNip,
    companyAddress,
    companyCity,
    companyZip,
    companyCountry,
    usersCount,
  };

  if (userEmails.length > 0) {
    data.userEmails = userEmails;
  }

  if (item.subscriptionOptions.promoCode) {
    data.promoCode = item.subscriptionOptions.promoCode;
  }

  if (checkout.promoCode) {
    if (data.promoCode && item.subscriptionOptions.promoCode) {
      throw new Error('Item already has a promo code.');
    }

    data.promoCode = checkout.promoCode.code;
  }

  return data;
};

export const wrPromoCodeToPromoCode = (wrPromoCode: WRPromoCode): PromoCode => {
  return wrPromoCode;
};

export const wrPaymentToCustomerData = (wrPayment: WRPayment): CustomerData => ({
  name: wrPayment.client,
  email: wrPayment.email,
  phone: wrPayment.phone,
  address: wrPayment.address,
  city: wrPayment.city,
  zip: wrPayment.zip,
  country: wrPayment.country,
});

export const wrPaymentToCompanyData = (wrPayment: WRPayment): CompanyData => ({
  name: wrPayment.company_name,
  nip: wrPayment.company_nip,
  address: wrPayment.company_address,
  city: wrPayment.company_city,
  zip: wrPayment.company_zip,
  country: wrPayment.company_country as any,
});
