export const getDefaultErrors = (): any => ({
  companyData: {
    nip: {
      invalidNip: 'Invalid NIP number',
    },
    zip: {
      invalidZipCode: 'Invalid zip code',
    },
  },
  customerData: {
    phone: {
      invalidPhone: 'Invalid phone number',
    },
    zip: {
      invalidZipCode: 'Invalid zip code',
    },
  },
});
