import { create } from 'zustand';

import { checkoutToPaymentCreate, wrPromoCodeToPromoCode } from './api';
import { getDefaultErrors } from './errors';
import { getItemById } from './items';
import { createCheckoutSchema } from './schema';
import { CustomerData, CompanyData, CheckoutItem, CheckoutSubscriptionItem, CheckoutSchema, PromoCode } from './types';

import { fetchApi } from '@utils/api/client';
import { WRPayment, WRPromoCode } from '@utils/api/server';
import { Packages } from 'src/app/lib/serverPackages';

const checkoutSchema = createCheckoutSchema(getDefaultErrors());

interface PaymentCreateResponse {
  sessionId: string;
  redirectUrl: string;
}
export interface CheckoutStore extends CheckoutSchema {
  eligibleForMonthlyTrial: boolean;
  packages?: Packages;
  setPackages: (packages: Packages) => void;
  setItems: (items: (CheckoutItem | CheckoutSubscriptionItem)[]) => void;
  setCustomerData: (customerData: CustomerData) => void;
  setCompanyData: (companyData: CompanyData) => void;
  checkMonthlyTrialEligibility: () => Promise<void>;
  applyPromoCode(code: string): Promise<boolean>;
  removePromoCode: () => void;
  finish(): Promise<{ redirectUrl: string }>;
  close: () => void;
}

export const useCheckoutStore = create<CheckoutStore>()((set) => {
  return {
    status: 'NEW',
    promoCode: null,
    items: [],
    customerData: null,
    companyData: null,
    eligibleForMonthlyTrial: true,
    setPackages: (packages: Packages) => set({ packages }),
    setPromoCode: (promoCode: PromoCode | null) => set({ promoCode }),
    setItems: (items) => set({ items }),
    setCustomerData: (customerData: CustomerData) => set({ customerData }),
    setCompanyData: (companyData: CompanyData) => set({ companyData }),
    async checkMonthlyTrialEligibility() {
      set({ eligibleForMonthlyTrial: true });
      if (!this.companyData) {
        return;
      }

      const response = await fetchApi<WRPayment | null>(`/premium/payment/by-nip/${this.companyData.nip}`);

      if (response.status !== 200 || !response.data) {
        return;
      }

      if (['PAID', 'REFUNDED'].includes(response.data.payment_status)) {
        set({ eligibleForMonthlyTrial: false });
        return;
      }
    },
    async applyPromoCode(code: string) {
      const response = await fetchApi<WRPromoCode>(`/premium/promo-code/${code}`, {
        noAuth: true,
        onFailure: 'continue',
      });

      if (response.status !== 200 || !this.packages) {
        return false;
      }

      const promoCode = wrPromoCodeToPromoCode(response.data);

      if (
        promoCode.promo_type === 'free-trial' ||
        (promoCode.promo_type === 'deals' && promoCode.applies_to_monthly && !promoCode.applies_to_yearly)
      ) {
        const subscriptionMonthlyItem = getItemById('premium-monthly', this.packages);

        if (!subscriptionMonthlyItem) {
          throw new Error('Failed to get subscription monthly item.');
        }

        set({
          items: [
            {
              id: subscriptionMonthlyItem.id,
              emails: [],
              quantity: promoCode.free_users_count || 1,
            },
          ],
        });
      } else if (
        promoCode.promo_type === 'yearly-discount' ||
        (promoCode.promo_type === 'deals' && promoCode.applies_to_yearly && !promoCode.applies_to_monthly)
      ) {
        const subscriptionYearlyItem = getItemById('premium-yearly', this.packages);

        if (!subscriptionYearlyItem) {
          throw new Error('Failed to get subscription yearly item.');
        }

        set({
          items: [
            {
              id: subscriptionYearlyItem.id,
              emails: [],
              quantity: 1,
            },
          ],
        });
      }

      set({ promoCode });
      return true;
    },
    removePromoCode() {
      set({ promoCode: null });
    },
    async finish() {
      if (!this.customerData) {
        throw new Error('Missing customer data.');
      }

      if (!this.companyData) {
        throw new Error('Missing company data.');
      }

      if (!this.packages) {
        throw new Error('Missing packages data.');
      }

      const checkout: Omit<CheckoutSchema, 'promoCodeData'> = {
        status: 'PENDING',
        promoCode: this.promoCode,
        items: this.items,
        customerData: this.customerData,
        companyData: this.companyData,
      };

      const validated = checkoutSchema.safeParse(checkout);

      if (!validated.success) {
        throw new Error('Invalid checkout data.');
      }

      set({ ...validated.data });

      const transformed = checkoutToPaymentCreate(validated.data, this.packages);

      const payment = await fetchApi<PaymentCreateResponse>('/premium/payment/create', {
        body: JSON.stringify(transformed),
        method: 'POST',
      });

      return {
        redirectUrl: payment.data.redirectUrl,
      };
    },
    close() {
      set({ status: 'FINISHED' });
    },
  };
});
