import { Packages } from 'src/app/lib/serverPackages';

export interface ItemSubscriptionOptions {
  packageName: string;
  recurring: boolean;
  period: number;
  periodUnit: string;
  promoCode?: string;
  freeTrial?: {
    cycles: number;
  };
}

export interface Item {
  id: string;
  price: number;
  currency: 'PLN';
  subscriptionOptions?: ItemSubscriptionOptions;
}

export const getItems = (packages: Packages): Item[] => {
  return [
    {
      id: 'premium-monthly',
      price: packages.monthly.amount,
      currency: 'PLN',
      subscriptionOptions: {
        packageName: 'premium-monthly',
        recurring: true,
        period: 1,
        periodUnit: 'month',
        freeTrial: {
          cycles: 1,
        },
      },
    },
    {
      id: 'premium-yearly',
      price: packages.yearly.amount,
      currency: 'PLN',
      subscriptionOptions: {
        packageName: 'premium-yearly',
        recurring: false,
        period: 1,
        periodUnit: 'year',
      },
    },
  ];
};

export const getItemById = (id: string, packages: Packages) => {
  const items = getItems(packages);
  return items.find((item) => item.id === id) || null;
};
