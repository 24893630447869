import { z } from 'zod';

export const createPhoneValidator = (errorMessage?: string) => {
  // TODO: Casting to z.ZodString is NOT correct, but it fixes type inference. Find a better way.
  // @ts-ignore
  return z.preprocess(
    (item) =>
      typeof item === 'string' ? item.replace(/\s/g, '') : String(item),
    z
      .string()
      .regex(/^(\d{9})$/, errorMessage)
      .min(9),
  ) as z.ZodString;
};

export const createZipCodeValidator = (errorMessage?: string) => {
  return z.string().regex(/^\d{2}-\d{3}$/, errorMessage);
};

export const createNipValidator = (errorMessage?: string) => {
  // TODO: Same case as phone validator.
  // @ts-ignore
  return z.preprocess(
    (item) =>
      typeof item === 'string' ? item.replace(/\s|-/g, '') : String(item),
    z.string().regex(/^(\d+)$/, errorMessage),
  ) as z.ZodString;
};

export const preprocessPossiblyNumber = (schema: z.Schema) =>
  // TODO: Casting to z.ZodNumber is NOT correct but it fixes type inference. Find a better way.
  // @ts-ignore
  z.preprocess(
    (item) => (typeof item === 'string' ? parseInt(item) : item),
    schema,
  ) as z.ZodNumber;

export const preprocessPossiblyNumberArray = (schema: z.Schema) =>
  // @ts-ignore
  z.preprocess((item) => {
    if (Array.isArray(item)) {
      return item.map((subItem) =>
        typeof subItem === 'string' ? parseInt(subItem) : subItem,
      );
    }
    return typeof item === 'string' ? parseInt(item) : item;
  }, schema) as z.ZodNumber;

export const createPasswordSchema = (errorMessage?: string) =>
  z.string().regex(/^(?=.*\d)(?=.*[A-Za-z])[0-9A-Za-z\W]{5,30}$/, errorMessage);
